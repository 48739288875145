function Checkbox(props) {
    return (
        <div>
            <input type="checkbox" id={props.id} checked={props.checked} onChange={props.onChange} />
            <label htmlFor={props.id} style={styles.label}>
                {props.label}
            </label>
        </div>
    );
}

const styles = {
    label: {
        marginLeft: '5px',
    },
}

export default Checkbox;