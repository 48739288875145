import { useEffect, useState } from "react";
import { mcIsLoggedIn } from "../apiClient";

const TABS = [
    {
        title: "Dashboard",
        href: "/dashboard",
    },
    {
        title: "Order Form",
        href: "/order-form",
    },
];
const INACTIVE_CLASS = 'btn btn-outline-primary';
const ACTIVE_CLASS = 'btn btn-outline-primary active';

function NavBar(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(function() {
        async function fetchData() {
            const t = await mcIsLoggedIn();
            setIsLoggedIn(!!t);
        }

        fetchData();
    }, [props.page]);

    return (
        <div className="bg-light" style={{padding:'10px', marginBottom:'20px'}}>
            <a href="/" style={{textDecoration:'none'}}>
                <img src="/banner.png" alt="MouthClub banner" width="207" style={{marginRight:'60px'}} />
            </a>

            {isLoggedIn && <div className="btn-group">
                {
                    TABS.map(t =>
                        (<a
                            href={t.href}
                            key={t.title}
                            name="navbar-group"
                            className={t.href===props.page ? ACTIVE_CLASS : INACTIVE_CLASS}
                        >
                            {t.title}
                        </a>)
                    )
                }
            </div>}

            {isLoggedIn && <a href="/logout" rel="noreferrer" className="btn btn-outline-danger" style={{float:'right'}}>
                Log Out
            </a>}
        </div>
    );
}

export default NavBar;