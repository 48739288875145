import React, { useState } from "react";
import { mcLogIn } from "../apiClient";

function Login(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState(false);
    const [response, setResponse] = useState('');

    const submitForm = async () => {
        // TODO: add some basic input validation
        const res = await mcLogIn(username, password);
        if (res.is2xx) {
            setError(false);
            props.navigate('/dashboard');
        } else if (res.is4xx) {
            setError(true);
            setResponse('Incorrect credentials. Please try again.');
        }
    };

    return (
        <div style={styles.div}>
            <div className="col-8 offset-md-2">
                <p style={styles.p}>
                    Please log in.
                </p>
            </div>

            {!!response && <div className="col-8 offset-md-2">
                <p style={error ? styles.error : styles.p}>
                    {response}
                </p>
            </div>}

            <div>
                <div className="col-4 offset-md-4" style={styles.row}>
                    <input type="text" className="form-control" placeholder="Username/Email" required size="255" value={username} onChange={evt => setUsername(evt.target.value)}/>
                </div>

                <div className="col-4 offset-md-4" style={styles.row}>
                    <input type="password" className="form-control" placeholder="Password" required size="50" value={password} onChange={evt => setPassword(evt.target.value)} />
                </div>

                <div className="col-4 offset-md-4" style={styles.row}>
                    <button className="btn btn-primary" style={styles.rspacer} onClick={submitForm}>Log In</button>
                    <a href="/forgot-password">Forgot password?</a>
                </div>
            </div>
        </div>
    );
}

const styles = {
    div: {
        marginBottom: 54,
    },
    p: {
        color: '#024e99',
        fontSize: '20px',
        fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
        textAlign: 'center',
    },
    error: {
        color: 'red',
        fontSize: '20px',
        fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
        textAlign: 'center',
    },
    row: {
        marginTop: '10px',
    },
    rspacer: {
        marginRight: '10px',
    },
};

export default Login;