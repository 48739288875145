import { useState } from "react";

function Input(props) {
    const [value, setValue] = useState(props.value);

    return (
        <div>
            <label style={styles.label}>{props.title}</label>
            <input {...props} value={value} onChange={evt => setValue(evt.target.value)} />
        </div>
    );
}

const styles = {
    label: {
        position: 'relative',
        fontSize: '10',
        
    },
}

export default Input;