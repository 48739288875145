import { useState } from "react";

import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Index from "./pages/Index";
import Dashboard from "./pages/Dashboard";
import OrderForm from "./pages/OrderForm";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import ForgotPassword from "./pages/ForgotPassword";
import ErrorNotFound from "./pages/ErrorNotFound";

function App() {
    const [page, setPage] = useState(window.location.pathname);

    const navigate = (val) => {
        window.location.href = val;
    };

    const getPageToRender = () => {
        if (page === '/') {
            return <Index navigate={navigate} />
        }

        if (page === '/dashboard') {
            return <Dashboard navigate={navigate} />
        }

        if (page === '/login') {
            return <Login navigate={navigate} />;
        }

        if (page === '/logout') {
            return <Logout navigate={navigate} />;
        }

        if (page === '/forgot-password') {
            return <ForgotPassword navigate={navigate} />;
        }

        if (page === '/order-form') {
            return <OrderForm navigate={navigate} patient={{}} />
        }

        return <ErrorNotFound navigate={navigate} />;
    }

    return (
        <div className="App">
            <NavBar page={page} />

            {getPageToRender()}

            <Footer />
        </div>
    );
}

export default App;
