function ErrorNotFound() {
    return (
        <div style={styles.div}>
            <div className="col-8 offset-md-2">
                <p style={styles.error}>
                    Page Not Found
                </p>
            </div>
        </div>
    );
}

const styles = {
    div: {
        marginBottom: 54,
    },
    error: {
        color: 'red',
        fontSize: '20px',
        fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
        textAlign: 'center',
    },
};

export default ErrorNotFound;