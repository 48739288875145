import { useEffect, useState } from "react";
import { mcGetMyLocations } from "../apiClient";

function Dashboard(props) {
    const [locations, setLocations] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const locationRes = await mcGetMyLocations();
            if (locationRes.is2xx) {
                setLocations(locationRes.body);
            } else if (locationRes.status === 401) {
                props.navigate('/login');
            }
        }

        fetchData();
    }, []);

    return (
        <div style={styles.div}>
            {!!locations && locations.map(loc => (
                <div style={styles.bordered} key={`${loc.id}`}>
                    <span style={styles.bold}>{loc.account_name}</span><br/>
                    <span>{loc.address_line1}<br/></span>
                    {!!loc.address_line2 && <span>{loc.address_line2}<br/></span>}
                    <span>{loc.address_city}, {loc.address_state} {loc.address_zip}</span>
                </div>
            ))}
        </div>
    );
}

const styles = {
    div: {
        marginTop: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '55%',
    },
    bold: {
        fontWeight: 'bold',
    },
    bordered: {
        marginTop: '10px',
        borderWidth: '1px',
        borderColor: 'grey',
        borderStyle: 'solid',
        padding: '5px'
    },
    p: {
        color: '#024e99',
        fontSize: '23px',
        fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
        textAlign: 'center',
    },
};

export default Dashboard;