import { useEffect } from "react";
import { mcIsLoggedIn } from "../apiClient";

function Index(props) {
    useEffect(() => {
        const navToAppropriatePage = async () => {
            const flag = await mcIsLoggedIn();
            if (flag) {
                props.navigate('/dashboard');
            } else {
                props.navigate('/login');
            }
        }

        navToAppropriatePage();
    }, []);

    return (
        <></>
    );
}

export default Index;