import Input from "../components/Input";
import Checkbox from "../components/Checkbox";
import { useEffect, useState } from "react";
import { mcGetMarketingCampaigns, mcGetProducts, mcSubmitOrder } from "../apiClient";

function OrderForm(props) {
    const [chartNumber, setChartNumber] = useState(props.patient.chartNumber);
    const [firstName, setFirstName] = useState(props.patient.firstName);
    const [lastName, setLastName] = useState(props.patient.lastName);
    const [email, setEmail] = useState(props.patient.email);
    const [phoneNumber, setPhoneNumber] = useState(props.patient.phoneNumber);
    const [gender, setGender] = useState(props.patient.gender);
    const [marketingCampaigns, setMarketingCampaigns] = useState(null);
    const [products, setProducts] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const campaignRes = await mcGetMarketingCampaigns();
            for (let mc of campaignRes.body) {
                mc.checked = false;
            }
            setMarketingCampaigns(campaignRes.body);

            const productRes = await mcGetProducts();
            for (let p of productRes.body) {
                p.checked = false;
            }
            setProducts(productRes.body);
        }

        fetchData();
    }, []);

    const onChange = (arr, id) => {
        const idx = arr.findIndex(x => x.id===id);
        if (idx !== -1) {
            const arr2 = [...arr];
            const x = {...arr2[idx]};
            x.checked = !x.checked;
            arr2[idx] = x;
            return arr2;
        }
        return arr;
    };

    const onChangeCampaign = (id) => {
        setMarketingCampaigns(onChange(marketingCampaigns, id));
    };

    const onChangeProduct = (id) => {
        setProducts(onChange(products, id));
    };

    const submitForm = async () => {
        const campaignIds = marketingCampaigns.filter(mc => mc.checked).map(mc => mc.id);
        const productIds = products.filter(p => p.checked).map(p => p.id);
        mcSubmitOrder(props.patient, campaignIds, productIds);
    };

    return (
        <div style={styles.div}>
            <div className="input-group">
                <Input title="Chart number" type="text" placeholder="Chart number" className="formfield form-control" />
                <Input title="First name" type="text" placeholder="First name" className="formfield form-control" />
                <Input title="Last name" type="text" placeholder="Last name"  className="formfield form-control" />
                <Input title="Email" type="email" placeholder="Email" className="formfield form-control" />
                <Input title="Phone number" type="tel" id="phone" className="formfield form-control" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" placeholder="xxx-xxx-xxxx" maxLength="10" minLength="10" />

                <div>
                    <label>Gender</label>
                    <select className="form-select" defaultValue="">
                        <option value="">Select...</option>
                        <option value="m">Male</option>
                        <option value="f">Female</option>
                    </select>
                </div>
            </div>

            <div>
                <div className="row align-items-start">
                    <div className="col" style={styles.div}>
                        <h4 style={styles.h4title}>SELECT MARKETING CAMPAIGN</h4>
                        {!!marketingCampaigns && marketingCampaigns.map(mc => (
                            <Checkbox key={`${mc.id}`} label={mc.name} id={`mc-chb-${mc.id}`} checked={mc.checked} onChange={() => onChangeCampaign(mc.id)} />
                        ))}
                    </div>
                    <div className="col" style={styles.div}>
                        <h4 style={styles.h4title}>SUGGEST PRODUCTS</h4>
                        {!!products && products.map(p => (
                            <Checkbox key={`${p.id}`} label={p.name} id={`p-chb-${p.id}`} checked={p.checked} onChange={() => onChangeProduct(p.id)} />
                        ))}
                    </div>
                </div>
            </div>

            <div>
                <button className="btn btn-primary" onClick={submitForm}>Submit</button>
            </div>
        </div>
    );
}

const styles = {
    div: {
        marginTop: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '90%',
    },
    form: {
        gridArea: 'Fo',
        paddingLeft: '20px',
        backgroundColor: 'rgb(208, 211, 214)',
        display: 'grid',
        gridGap: '3px',
        gridTemplateColumns: 'subgrid',
        gridTemplateRows: 'subgrid',
    },
    p: {
        color: '#024e99',
        fontSize: '23px',
        fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
        textAlign: 'center',
    },
    h4title: {
        color: '#f88f06',
    },
};

export default OrderForm;