import { mcLogOut } from "../apiClient";

function Logout(props) {
    setTimeout(async function() {
        await mcLogOut();
        props.navigate('/login');
    }, 1000);

    return (
        <div style={styles.div}>
            <p style={styles.p}>
                Logging out...
            </p>
        </div>
    );
}

const styles = {
    div: {
        marginTop: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '55%',
    },
    p: {
        color: '#024e99',
        fontSize: '23px',
        fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
        textAlign: 'center',
    },
};

export default Logout;