function Footer(props) {
    return (
        <div style={styles.main}>
            <span>&copy; 2021 MouthClub LLC</span>
            {/* <span style={styles.link}><a href="/faq">FAQ</a></span> */}
            {/* <span style={styles.link}><a href="/privacy-policy">Privacy Policy</a></span> */}
            {/* <span style={styles.link}><a href="/terms-of-use">Terms of Use</a></span> */}
        </div>
    );
}

const styles = {
    main: {
        position: 'fixed',
        bottom: '0px',
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
        width: '100%',
        backgroundColor: '#f8f9fa',
    },
    link: {
        borderLeftWidth: 1,
        borderLeftColor: 'black',
        borderLeftStyle: 'solid',
        paddingLeft: 10,
        marginLeft: 10,
    },
}

export default Footer;